<template>
  <div>
    <PassageForm
      :passage="passage"
      :sectionName="sectionName"
      @setPassage="data => setPassage(data)"
    />
    <div style="text-align: center; margin-bottom: 20px">
      <el-button style="margin: 0px 4px" type="success" @click="onSubmit">
        {{ $t("alert.save") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import PassageForm from "@/components/tests/PassageForm.vue";
import satApi from "@/apis/sat.js";

export default {
  components: {
    PassageForm
  },
  props: ["initPassage", "sectionName"],
  computed: {
    sectionIsWriting() {
      return this.sectionName === "writing";
    }
  },
  data() {
    return {
      searchVal: "",
      visibleSearchPassageDialog: false,
      visiblePreviewPassageDialog: false,
      searchedPassages: [],
      previewPassage: {},
      showPassage2: false,
      passage: {
        title: "",
        intro: "",
        content: "",
        below_content: ""
      }
    };
  },
  watch: {
    initPassage() {
      this.setInitPassage();
    }
  },
  mounted() {
    this.setInitPassage();
  },
  methods: {
    setInitPassage() {
      if (this.initPassage) {
        this.passage = { ...this.initPassage };
      }

      if (this.passage.below_content) {
        this.showPassage2 = true;
      }
    },
    toggleSearchPassage(status) {
      this.visibleSearchPassageDialog = status;
    },
    async searchPassages() {
      this.searchedPassages = await satApi.getSubjectPassages(
        this.$route.query.sectionId,
        this.searchVal
      );
    },
    copyThisPassageToForm(passage) {
      this.setPassage({
        title: passage.title,
        intro: passage.intro,
        content: passage.content,
        below_content: passage.below_content
      });
      this.toggleSearchPassage(false);
      this.visiblePreviewPassageDialog = false;
    },
    openPreviewPassage(passage) {
      this.previewPassage = passage;
      this.visiblePreviewPassageDialog = true;
    },
    setPassage(data) {
      this.passage = {
        ...this.passage,
        ...data
      };
    },
    onSubmit() {
      const removeSuffixedSpace = (content) => {
        while(content.includes("&nbsp; </p>") || content.includes("&nbsp;</p>")) {
          content = content.replace("&nbsp; </p>", "</p>").replace("&nbsp;</p>", "</p>");
        }
        return content;
      };
      const postPassage = {
        ...this.passage,
        content: removeSuffixedSpace(this.passage.content || ""),
        below_content: removeSuffixedSpace(this.passage.below_content || "")
      };
      this.$emit("onSubmit", postPassage);
    }
  }
};
</script>
